import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import translations from 'assets/translations/translations'
import PropTypes from 'prop-types'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  max-height: ${(props) => (props.isClicked ? props.maxHeight : '0')};
  display: ${(props) => (props.isClicked ? 'block' : '')};
  ${MEDIA.MIN_DESKTOP`
    max-height: initial;
    `}
`
const ContentWrapper = styled.div`
  margin: 20px 0 20px 0;
  font-size: ${(props) => props.theme.fontSizes.mregular};
  font-weight: ${(props) => props.theme.fontWeights.light};
  ${MEDIA.MIN_TABLET`
    margin: 30px 0 30px 0;
    font-size: ${(props) => props.theme.fontSizes.tregular};
    `}
`
const ListUl = styled.ul`
  list-style: inside;
  margin: 20px 0 20px 0;
  & li {
    list-style-position: inside;
    text-indent: -1.5em;
    padding-left: 1.5em;
  }
`

const ListLi = styled.li`
  &::marker {
    color: ${(props) => props.theme.colors.blue};
  }
  & span {
    position: relative;
    left: -10px;
  }
`
const NumberContainer = styled.div`
  color: ${(props) => props.theme.colors.blue};
  font-size: ${(props) => props.theme.fontSizes.mprice};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  ${MEDIA.MIN_TABLET`
    margin: 30px 0 30px 0;
    font-size: ${(props) => props.theme.fontSizes.tprice};
    `}
`

const NumberWrapper = styled.div`
  display: grid;
  margin: 20px 0 20px 0;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  grid-template-columns: max-content 1fr;
`

const DescWrapper = styled.div`
  text-align: justify;
`

const OfferContent = ({
  isClicked,
  descOne,
  points,
  descTwo,
  price,
  isPrice,
}) => {
  const containerRef = useRef(null)
  const [maximumHeight, setMaximumHeight] = useState(null)

  const changeHeight = () => {
    setMaximumHeight(containerRef.current.scrollHeight)
  }

  useEffect(() => {
    setMaximumHeight(containerRef.current.scrollHeight)
    window.addEventListener('resize', changeHeight)
    return () => {
      window.removeEventListener('resize', changeHeight)
    }
  }, [])

  return (
    <Container
      ref={containerRef}
      maxHeight={`${maximumHeight}px`}
      isClicked={isClicked}
    >
      <ContentWrapper>
        <DescWrapper>{descOne}</DescWrapper>
        <ListUl>
          {points.map((el) => (
            <ListLi>
              <span>{el}</span>
            </ListLi>
          ))}
        </ListUl>
        <DescWrapper>{descTwo}</DescWrapper>
        {isPrice && (
          <NumberWrapper>
            {translations.minimalPrice}
            <NumberContainer>{price}</NumberContainer>
          </NumberWrapper>
        )}
      </ContentWrapper>
    </Container>
  )
}

OfferContent.propTypes = {
  isClicked: PropTypes.bool.isRequired,
  descOne: PropTypes.string.isRequired,
  descTwo: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  isPrice: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  points: PropTypes.any.isRequired,
}

export default OfferContent
