import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import MarginWrapper from 'components/UI/MarginWrapper/MarginWrapper'
import OfferContent from 'components/Offer/OfferContent/OfferContent'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  background-color: ${(props) =>
    props.isClicked ? 'none' : props.theme.colors.blue};
  width: 100vw;
  border-bottom: ${(props) =>
    props.isClicked ? '1px #0047BA solid' : '1px #fff solid'};
  position: relative;
  left: 50%;
  transition: all 0.2s ease-in-out;
  margin-left: -50vw;
  cursor: pointer;
`
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto auto;
  grid-column-gap: 25px;
  grid-auto-flow: column;
  align-items: center;
  ${MEDIA.MIN_TABLET`
    grid-column-gap: 35px;
    `}
`

const BigNum = styled.div`
  font-size: ${(props) => props.theme.fontSizes.mheadXXL};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  transition: all 0.2s ease-in-out;
  color: ${(props) =>
    props.isClicked ? props.theme.colors.blue : props.theme.colors.white};
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.theadXL};
    `}
`
const Text = styled.div`
  font-size: ${(props) => props.theme.fontSizes.mheadSmall};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  transition: all 0.2s ease-in-out;
  color: ${(props) =>
    props.isClicked ? props.theme.colors.black : props.theme.colors.white};
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.theadSmall};
    `}
`

const OfferItem = ({
  number,
  title,
  descOne,
  points,
  descTwo,
  price,
  isPrice,
}) => {
  const [iconClicked, setIconClicked] = useState(false)

  return (
    <>
      <Container
        onClick={() => setIconClicked(!iconClicked)}
        isClicked={iconClicked}
      >
        <MarginWrapper>
          <GridWrapper>
            <BigNum isClicked={iconClicked}>{number}</BigNum>
            <Text isClicked={iconClicked}>{title}</Text>
          </GridWrapper>
        </MarginWrapper>
      </Container>
      <OfferContent
        isPrice={isPrice}
        descOne={descOne}
        points={points}
        descTwo={descTwo}
        price={price}
        isClicked={iconClicked}
      />
    </>
  )
}

OfferItem.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  descOne: PropTypes.string.isRequired,
  descTwo: PropTypes.string.isRequired,
  isPrice: PropTypes.bool.isRequired,
  price: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  points: PropTypes.array.isRequired,
}

export default OfferItem
