import React from 'react'
import styled from 'styled-components'
import translations from 'assets/translations/translations'
import PropTypes from 'prop-types'
import MEDIA from 'utils/helpers/media'

const InnerContent = styled.div`
  margin-top: 30px;
  display: grid;
  grid-column-gap: 100px;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
`

const ContentWrapper = styled.div`
  border-top: ${(props) => `10px ${props.theme.colors.blue} solid`};
  font-size: ${(props) => props.theme.fontSizes.tregular};
  font-weight: ${(props) => props.theme.fontWeights.light};
`
const ListUl = styled.ul`
  grid-row: 1/2;
  grid-column: 2/3;
  list-style: inside;
  & li {
    list-style-position: inside;
    text-indent: -1.5em;
    padding-left: 1.5em;
  }
`

const ListLi = styled.li`
  &::marker {
    color: ${(props) => props.theme.colors.blue};
  }
  & span {
    position: relative;
    left: -10px;
  }
`
const NumberContainer = styled.div`
  color: ${(props) => props.theme.colors.blue};
  font-size: ${(props) => props.theme.fontSizes.mprice};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  ${MEDIA.MIN_TABLET`
    margin: 30px 0 30px 0;
    font-size: ${(props) => props.theme.fontSizes.tprice};
    `}
`

const NumberWrapper = styled.div`
  display: grid;
  grid-row: 2/3;
  grid-column: 1/2;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  grid-template-columns: max-content 1fr;
  ${MEDIA.MIN_DESKTOP`
    display: none;
    `}
`

const DescWrapper = styled.div`
  text-align: justify;
  grid-row: 1/3;
  grid-column: 1/2;
`

const OfferContentDesktop = ({ descOne, points, descTwo, price, isPrice }) => (
  <ContentWrapper>
    <InnerContent>
      <DescWrapper>
        {descOne}
        {descTwo}
      </DescWrapper>
      <div>
        <ListUl>
          {points.map((el) => (
            <ListLi>
              <span>{el}</span>
            </ListLi>
          ))}
        </ListUl>
      </div>
      {isPrice && (
        <NumberWrapper>
          {translations.minimalPrice}
          <NumberContainer>{price}</NumberContainer>
        </NumberWrapper>
      )}
    </InnerContent>
  </ContentWrapper>
)

OfferContentDesktop.propTypes = {
  descOne: PropTypes.string.isRequired,
  descTwo: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  isPrice: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  points: PropTypes.any.isRequired,
}

export default OfferContentDesktop
