import React from 'react'
import { useSelector } from 'react-redux'
import SEO from 'components/SEO/SEO'
import styled from 'styled-components'
import translations from 'assets/translations/translations'
import PageIntro from 'components/PageIntro/PageIntro'
import PageHeader from 'components/Typography/Headers/PageHeader'
import OfferItem from 'components/Offer/OfferItem'
import OfferItemDesktop from 'components/Offer/OfferItemDesktop'
import MEDIA from 'utils/helpers/media'
import Footer from 'components/Footer/Footer'
import MarginBottom from 'components/UI/MarginBottom/MarginBottom'

const OfferItemsList = styled.div`
  margin-bottom: 50px;
  display: grid;
  grid-auto-flow: row;
  ${MEDIA.MIN_DESKTOP`
    display: none;
    `}
`
const OfferItemsListDesktop = styled.div`
  display: none;
  margin-bottom: 100px;
  grid-auto-flow: row;
  ${MEDIA.MIN_DESKTOP`
   display: grid;
   grid-row-gap: 50px;
    `}
`

const offerItems = [
  {
    number: '01',
    title: 'Usługi Księgowe',
    descOne:
      'Prezentujemy szeroki zakres usług księgowych i rachunkowych działalności gospodarczych. Obsługa księgowa oferowana przez Kancelarię pozwala Klientom skoncentrować się na prowadzeniu działalności gospodarczej, bazując na wiedzy i doświadczeniu naszych pracowników.',
    points: [
      'Prowadzenie ksiąg rachunkowych',
      'Prowadzenie podatkowej księgi przychodów i rozchodów',
      'Obliczanie zaliczek na podatek dochodowy i  podatek VAT',
      'Przygotowywanie przelewów do ZUS i US',
      'Rozliczenia transakcji międzynarodowych, w tym wewnątrzwspólnotowych nabyć i dostaw',
      'Sporządzenie rocznych zeznań podatkowych i sprawozdań finansowych Spółek',
      'Przygotowywanie sprawozdań statystycznych do GUS',
    ],
    descTwo:
      ' Kancelaria zapewnia bogatą ofertę w zakresie sprawozdawczości finansowej podmiotów gospodarczych, a w szczególności spółek kapitałowych.',
    price: '150 zł',
    isPrice: true,
  },
  // {
  //   number: '02',
  //   title: 'Doradztwo podatkowe',
  //   descOne:
  //     'Usługi z zakresu doradztwa podatkowego obejmują wszelkie aspekty funkcjonowania działalności gospodarczej. Nasze ponad 25 letnie doświadczenie gwarantuje wysoką skuteczność w rozwiązywaniu problemów podatkowych Klientów. Dzięki wiedzy i profesjonalizmowi zatrudnionych pracowników, jesteśmy w stanie podjąć się najtrudniejszych wyzwań prawno-podatkowych.',
  //   points: [
  //     'Wsparcie firm podczas kontroli skarbowej i prowadzonych postępowaniach podatkowych',
  //     'Optymalizacje podatkową',
  //     'Analizę i korektę projektów umów pod kątem zagrożeń podatkowych',
  //     'Sporządzanie wniosków kredytowych, biznes planów',
  //     'Wnioski o dotacje unijne, dotacje WUP i PUP',
  //     'Audyt rachunkowo-podatkowy',
  //     'Sporządzanie wniosków o wydanie interpretacji przepisów prawa podatkowego',
  //     'Sporządzanie zażaleń na postępowania organów podatkowych',
  //   ],
  //   descTwo:
  //     'Reprezentujemy klientów w postępowaniach podatkowych przed organami podatkowymi we wszystkich instancjach oraz sądami administracyjnymi. Pomagamy w przygotowaniu dokumentów dowodowych, wyjaśnień oraz wniosków i odwołań.',
  //   price: '100 zł',
  //   isPrice: true,
  // },
  {
    number: '02',
    title: 'Kadry i płace',
    descOne:
      'Kancelaria ofertuję kompleksową usługę z zakresu zarządzania kadrami w przedsiębiorstwach naszych Klientów. W ramach usługi prowadzimy całkowicie zintegrowany dział personalny, umożliwiając tym samym redukcję kosztów oraz korzystanie z pełnej palety obsługi w zakresie HR.',
    points: [
      'Naliczanie wynagrodzeń i sporządzanie listy płac',
      'Sporządzanie i składanie miesięcznych deklaracji rozliczeniowych ZUS',
      'Rozliczanie umów zleceń i o dzieło',
      'Rejestracja i wyrejestrowanie pracowników w ZUS',
      'Prowadzenie akt osobowych',
      'Przeprowadzanie szkoleń z zakresu BHP',
      'Przygotowywanie informacji do komorników',
      'Obsługa zatrudnienia osób niepełnosprawnych',
    ],
    descTwo:
      ' Połączenie obsługi księgowej oraz kadrowej pozwala na najbardziej efektywne prowadzenie spraw działalności naszych Klientów.',
    price: '100 zł',
    isPrice: true,
  },
  {
    number: '03',
    title: 'Zakładanie działalności gospodarczej',
    descOne:
      'Osobom mającym pomysł na biznes, Kancelaria zapewnia kompleksową usługę związaną z zakładaniem działalności gospodarczej. Pomoc w zakresie zawiłości spraw formalnych podczas zakładania działalności gospodarczej spoczywa na Kancelarii, co pozwala naszym Klientom oszczędzić czas oraz skupić się na efektywnym wejściu przedsiębiorstwa na rynek.',
    points: [
      'Tworzenie, zmiana statutów i umów spółek handlowych ',
      'Wypełnianie wniosków rejestracji spółek osobych oraz prawnych',
      'Pomoc w przeprowadzeniu pełnego procesu rejestracji spółek w rejestrze przedsiębiorców (KRS), Urzędzie Skarbowym, Urzędzie statystycznym oraz ZUS',
      'Obsługa organów spółek (posiedzenia zarządu, rady nadzorczej, zgromadzenia wspólników)',
      'Stała obsługa korporacyjna spółek w tym zmiana umowy spółek, wypełnianie wniosków zgłoszenia informacji do KRS',
    ],
    descTwo:
      ' W przypadku zawarcia stałej umowy z Kancelarią, proponujemy bezpłatną usługę związaną z pomocą w uruchomieniu działalności gospodarczej. Usługi związane z zakładaniem działalności gospodarczej wykonywane są w ramach współpracy z zewnętrznym doradcą podatkowym.',
    price: '0 zł',
    isPrice: true,
  },
  {
    number: '04',
    title: 'Obsługa spółek kapitałowych',
    descOne:
      'Jako Kancelaria Michalak specjalizujemy się w obsłudze spółek kapitałowych, ich bieżącej działalności, jak również w procesach transformacyjnych podmiotów gospodarczych.',
    points: [
      'Prowadzenie ksiąg rachunkowych',
      'Przygotowywanie sprawozdań finansowych',
      'Pełne wsparcie bieżącej działalności spółek kapitałowych',
      'Pomoc podczas przeprowadzenia procesu przekształcenia spółek handlowych',
    ],
    descTwo:
      ' W przypadku podpisania z Kancelarią umowy na obsługę księgową, pomoc podczas procesu założenia spółki kapitałowej przeprowadzany jest przez Kancelarię bezpłatnie. Usługi związane z zakładaniem działalności gospodarczej, jak również procesami transformacji wykonywane są w ramach współpracy z zewnętrznym doradcą podatkowym.',
    price: '500 zł',
    isPrice: true,
  },
  {
    number: '05',
    title: 'Pozostałe usługi',
    descOne:
      'Kancelaria oferuje również szereg usług dodatkowych, zapewniając Klientom kompleksowość i profesjonalizm w każdym aspekcie finansowym i funkcjonowania przedsiębiorstwa. Wzbogacając portfolio oferowanych usług dajemy Klientom pewność, iż wszelkie sprawy podatkowe z zakresu bieżącego funkcjonowania przedsiębiorstw zostaną objęte przez naszą Kancelarię.',
    points: [
      'Prowadzenie obowiązkowej ewidencji formalnej z zakresu ochrony środowiska',
      'Przygotowywanie wniosków pozwalających na zwrot podatku VAT od części zakupów dokonywanych w krajach Unii Europejskiej',
      'Tworzenie wniosków o dotacje unijne',
      'Przygotowywanie wniosków w ramach tarczy podatkowej',
      'Pomoc podczas przygotowywania biznes planów, wniosków kredytowych',
    ],
    descTwo:
      'Wszystkie ceny w ramach pozostałych usług każdorazowo ustalane są indywidualnie z Klientem. Usługi dodatkowe wykraczające poza działalność Kancelarii wykonywane są w ramach współpracy z zewnętrznym doradcą podatkowym.',
    price: '50 zł',
    isPrice: true,
  },
]

const Oferta = () => {
  const location = useSelector(({ state }) => state.targetRoute)
  return (
    <>
      <SEO title={translations.pages.offer} pathname={location.pathname} />
      <PageIntro
        sectionLineHeader={translations.pages.offer}
        description={translations.descriptions.offer}
      >
        <PageHeader>{translations.headers.offer.broad}</PageHeader>
        <PageHeader>{translations.headers.offer.area}</PageHeader>
        <PageHeader blue>{translations.headers.offer.services}</PageHeader>
      </PageIntro>
      <OfferItemsListDesktop>
        {offerItems.map(
          ({ number, title, descOne, descTwo, points, price, isPrice }) => (
            <OfferItemDesktop
              key={number}
              number={number}
              title={title}
              descOne={descOne}
              descTwo={descTwo}
              points={points}
              price={price}
              isPrice={isPrice}
            />
          ),
        )}
      </OfferItemsListDesktop>
      <OfferItemsList>
        {offerItems.map(
          ({ number, title, descOne, descTwo, points, price, isPrice }) => (
            <OfferItem
              key={number}
              number={number}
              title={title}
              descOne={descOne}
              descTwo={descTwo}
              points={points}
              price={price}
              isPrice={isPrice}
            />
          ),
        )}
      </OfferItemsList>
      <MarginBottom />
      <Footer />
    </>
  )
}

export default Oferta
