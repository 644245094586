import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import OfferContentDesktop from 'components/Offer/OfferContent/OfferContentDesktop'
import MEDIA from 'utils/helpers/media'
import translations from 'assets/translations/translations'

const Container = styled.div`
  position: relative;
  margin-bottom: -20px;
`
const GridWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: max-content auto;
  grid-column-gap: 50px;
  ${MEDIA.MIN_DESKTOP`
    grid-template-columns: max-content auto auto;
    `}
`

const BigNum = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.colors.black};
  opacity: 0.5;
  font-size: ${(props) => props.theme.fontSizes.bignum};
`
const Text = styled.div`
  font-size: ${(props) => props.theme.fontSizes.dheadSmall};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.colors.black};
`

const NumberContainer = styled.div`
  display: none;
  ${MEDIA.MIN_DESKTOP`
    margin: 30px 0 30px 0;
    font-size: ${(props) => props.theme.fontSizes.tprice};
    color: ${(props) => props.theme.colors.blue};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  display: initial;
    `}
`

const NumberWrapper = styled.div`
  display: grid;
  grid-row: 2/3;
  grid-column: 1/2;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  grid-template-columns: max-content 1fr;
  ${MEDIA.MIN_DESKTOP`
    grid-row: 1/2;
  grid-column: 3/4;
  justify-self: end;
  font-size: ${(props) => props.theme.fontSizes.tregular};
    `}
`

const OfferItemDesktop = ({
  number,
  title,
  descOne,
  points,
  descTwo,
  price,
  isPrice,
}) => (
  <div>
    <Container>
      <GridWrapper>
        <BigNum>{number}</BigNum>
        <Text>{title}</Text>
        {isPrice && (
          <NumberWrapper>
            {translations.minimalPrice}
            <NumberContainer>{price}</NumberContainer>
          </NumberWrapper>
        )}
      </GridWrapper>
    </Container>
    <OfferContentDesktop
      isPrice={isPrice}
      descOne={descOne}
      points={points}
      descTwo={descTwo}
      price={price}
    />
  </div>
)

OfferItemDesktop.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  descOne: PropTypes.string.isRequired,
  descTwo: PropTypes.string.isRequired,
  isPrice: PropTypes.bool.isRequired,
  price: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  points: PropTypes.array.isRequired,
}

export default OfferItemDesktop
